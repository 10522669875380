<script setup>
import { computed, ref, onMounted } from 'vue'
import { useModalStore } from '@/store/modal'
import NbsIcon from '@/components/NbsIcon.vue'

const modalStore = useModalStore()
const modal = ref(null)
const modalHeight = ref(0)
const bodyHeight = ref(0)
const modalStyle = computed(() => {
  if (bodyHeight.value < modalHeight.value) {
    return { top: '1rem', transform: 'translate(-50%, 0)' }
  } else {
    return { top: '50%', transform: 'translate(-50%, -50%)' }
  }
})

const setNewHeight = () => {
  modalHeight.value = modal.value.clientHeight
  modalStore.height = modalHeight.value
}

onMounted(() => {
  bodyHeight.value = document.body.clientHeight
  if (modalStore.isOpened) {
    modalHeight.value = modal.value.clientHeight
  }
})
</script>

<template>
  <Teleport to="body">
    <div class="nbs-overlay"
          @click.self="modalStore.hasCloseOnOverlay ? modalStore.close : () => false"
          v-if="modalStore.isOpened"
          aria-modal="true"
          role="dialog"
          tabindex="-1">
          <div ref="modal"
              class="nbs-modal"
              :class="[ modalStore.htmlClasses, { 'nbs-modal--fixedHeight': modalStore.isFixedHeight } ]"
              :style="modalStyle">
            <button v-if="modalStore.hasCloseButton" type="button" class="nbs-modal-close" @click="modalStore.close">
              <nbs-icon icon="close" />
            </button>
            <component :is="modalStore.modalState?.component"
                        @on-change-height="setNewHeight"
                        @on-opened="setNewHeight"
                        v-bind="modalStore.modalState?.props"  />
          </div>
    </div>
  </Teleport>
</template>

<style lang="scss">
@import '@/assets/styles/_mixins.scss';
@import '@/assets/styles/_variables.scss';
.nbs-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: $black-64;
  overflow: auto;
  padding: $gutters;
}
.nbs-modal {
  position: relative;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 0 rem(32px) 0;
  width: rem(320px);
  border-radius: rem(24px);
  background-color: $white;
  transition: width 0.2s linear;

  &--400 {
    width: rem(400px);
  }

  &--480 {
    width: rem(480px);
  }

  &--740 {
    width: rem(740px);
  }

  &--800 {
    width: rem(800px);
  }

  &--fixedHeight {
    max-height: 100%;
  }

  &-close {
    position: absolute;
    top: rem(16px);
    right: rem(16px);
    z-index: 1;
    width: rem(48px);
    height: rem(48px);
    border-radius: rem(12px);
    background-color: rgba(255, 255, 255, 0.24);
    color: $black-24;
  }
}
</style>
