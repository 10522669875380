export const LOCALE = 'fr'

export const CUSTOMER_DOMAIN = 0
export const PROJECT_DOMAIN = 1
export const DOCUMENTS_DOMAIN = 2
export const COMMUNITY_DOMAIN = 3
export const QUIZ_DOMAIN = 4

export const DOMAINS = {
  CUSTOMER_DOMAIN,
  PROJECT_DOMAIN,
  DOCUMENTS_DOMAIN,
  COMMUNITY_DOMAIN,
  QUIZ_DOMAIN
}

export const DOMAINS_LABELS = [
  'comptes',
  'projets',
  'documents',
  'contacts',
  'questionnaires'
]

export const DOCUMENT_STORAGE = 'NAZ_DOCUMENT'
export const DOCUMENTS_LIST_STORAGE = 'NAZ_DOCUMENTS_LIST'

export const API_VERSION = 'v1'

export const LANGUAGES = {
  fr: 'Français',
  de: 'Allemand',
  it: 'Italien',
  sp: 'Espagnol',
  uk: 'Royaume-uni'
}

export const ALPHABET_LIST = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

export const GENDERS = ['Madame', 'Monsieur', 'Neutre']
export const GENDERS_OPTIONS = GENDERS.map((item, index) => {
  return { label: item, value: index }
})

export const CURRENCY_FORMAT = new Intl.NumberFormat(LOCALE + '-' + LOCALE.toUpperCase(), { style: 'currency', currency: 'EUR' })
export const TWO_DIGITS_FORMAT = new Intl.NumberFormat(LOCALE + '-' + LOCALE.toUpperCase(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })
