import { ref, watch, computed } from 'vue'

export const useFormField = ({ props, defaultValue, emit }) => {
  const prevValue = ref(defaultValue)
  const currentValue = ref(defaultValue)
  let initialValue = defaultValue

  const updateState = (modelValue) => {
    currentValue.value = typeof modelValue !== 'undefined' && modelValue !== null ? modelValue : ''
    if (props.showUpdate) {
      const newValue = typeof currentValue.value === 'boolean' ? currentValue.value : stringCurrent.value
      if (emit) {
        emit('onUpdate', {
          newValue,
          oldValue: typeof prevValue.value === 'boolean' ? prevValue.value : stringPrevious.value,
          initialValue: typeof initialValue === 'boolean' ? initialValue : stringInitial.value
        })
      }
    }
  }

  const stringInitial = computed(() => {
    return initialValue ? initialValue.toString() : ''
  })

  const stringPrevious = computed(() => {
    return prevValue.value ? prevValue.value.toString() : ''
  })

  const stringCurrent = computed(() => {
    return currentValue.value ? currentValue.value.toString() : ''
  })

  const isUpdated = computed(() => {
    return props.showUpdate && stringCurrent.value !== stringInitial.value && !props.reset
  })

  const setPrevValue = (newValue) => {
    prevValue.value = newValue
  }

  const reset = (newValue) => {
    if (newValue) {
      setPrevValue(stringCurrent.value)
      initialValue = stringCurrent.value
    }
  }

  watch(currentValue, setPrevValue)
  watch(() => props.reset, reset)

  return {
    isUpdated,
    stringInitial,
    stringCurrent,
    stringPrevious,
    initialValue,
    prevValue,
    currentValue,
    updateState,
    setPrevValue,
    reset
  }
}
export default useFormField
