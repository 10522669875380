<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import { FormFieldsProps } from '@/props'
import NbsIcon from '@/components/NbsIcon.vue'
const props = defineProps({
  ...FormFieldsProps,
  value: {
    type: String,
    default: ''
  },
  checked: {
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: 'checked'
  },
  position: {
    type: String,
    default: 'left'
  },
  border: {
    type: Boolean,
    default: false
  }
})
const model = ref(props.checked)
const emit = defineEmits(['onChange'])
const onChange = () => {
  emit('onChange', { checked: model.value, value: props.value })
}
const classesList = computed(() => {
  return [
    'nbs-checkbox--' + props.position,
    { 'nbs-checkbox--border': props.border },
    { 'nbs-checkbox--disabled': props.disabled }
  ]
})
</script>

<template>
    <label class="nbs-checkbox"
          :class="classesList">
        <input
          @change="onChange"
          type="checkbox"
          v-model="model"
          :id="props.id"
          :value="props.value"
          :checked="props.checked"
          :disabled="props.disabled" />
        <nbs-icon class="nbs-checkbox-icon" :icon="icon"></nbs-icon>
        <div class="nbs-checkbox-label">
          <span v-if="props.label !== ''">{{ props.label  }}</span>
          <slot></slot>
        </div>
    </label>
</template>

<style lang="scss">
@import '@/assets/styles/_mixins.scss';
@import '@/assets/styles/_variables.scss';

.nbs-checkbox {
    position: relative;
    min-width: rem(16px);
    min-height: rem(16px);

    &--left {
      input {
        left: 0;
      }

      .nbs-checkbox {
        &-label {
          padding-left: rem(24px);
        }
        &-icon {
          left: rem(3px);
        }
      }
    }

    &--right {
      input {
        right: 0;
      }

      .nbs-checkbox {
        &-label {
          padding-right: rem(24px);
        }
        &-icon {
          right: rem(3px);
        }
      }
    }

    &--border {
      display: block;
      margin: rem(4px) 0;
      border: rem(1px) solid transparent;
      border-radius: rem(12px);
      transition: $transition-border;

      &.nbs-checkbox--disabled {
        input[type=checkbox] {
          opacity: 0.48;
        }
      }

      &:hover:not(.nbs-checkbox--disabled) {
        border-color: $black-08;
      }

      .nbs-checkbox-label {
        padding: rem(8px) $gutters;
        border-radius: rem(12px);
        line-height: 1.25;
      }

      input[type=checkbox] {
        right: $gutters;
        top: rem(10px);

        &:checked ~ .nbs-checkbox-label {
          background-color: $black-02;
        }
      }

      .nbs-checkbox-icon {
        top: rem(13px);
      }

      &.nbs-checkbox--left {
        .nbs-checkbox-icon {
          left: calc($gutters + rem(3px));
        }
      }

      &.nbs-checkbox--right {
        .nbs-checkbox-icon {
          right: calc($gutters + rem(3px));
        }
      }
    }

    &-icon {
      position: absolute;
      top: rem(3px);
      width: rem(10px);
      height: rem(10px);
      transform: scale(0);
      transition: 120ms transform ease-in-out;
    }
}

input[type="checkbox"] {
    position: absolute;
    top: 0;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    width: rem(16px);
    height: rem(16px);
    border: rem(1px) solid $black-14;
    border-radius: rem(4px);

    &:checked {
        border-color: $black-88;
        outline: rem(1px) solid $black-88;

        & + .nbs-checkbox-icon {
          transform: scale(1);
          color: $black-88;
        }

        & ~ .nbs-checkbox-label {
          font-weight: 600;
          color: $black-88;
        }
    }
}
</style>
