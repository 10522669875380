import { ref, toValue } from 'vue'
import { defineStore } from 'pinia'
import moment from 'moment'
import { useAPI, useFile, useCommonObjDatas } from '@/composables'
import { DEFAULT_ADDRESS } from '@/datas/addresses'

moment.locale('fr')

const settingsDefault = {
  network: {},
  networks: [],
  address: DEFAULT_ADDRESS
}

export const useSettingsStore = defineStore('useSettingsStore', () => {
  const API = useAPI()
  const CommonObjDatas = useCommonObjDatas()
  const LogoFile = useFile()
  const CoverFile = useFile()
  const data = ref(settingsDefault)
  const files = ref({
    logo: null,
    cover: null,
    kbis: null
  })

  const setData = (value) => {
    data.value = Object.assign(data.value, toValue(value))
  }

  const fetch = async () => {
    try {
      const response = await API.fetch('/parameter', { method: 'get' })
      if (response) {
        setData(response.data)
        CommonObjDatas.setData(data.value)

        if (data.value._links.creator) {
          data.value.creator = await CommonObjDatas.fetchCreator()
        }

        if (data.value._links.editor) {
          data.value.editor = await CommonObjDatas.fetchEditor()
        }
      }
      return response
    } catch (e) {
      API.handleError(e)
    }
  }

  const fetchCountry = async ({ link, key }) => {
    try {
      const response = await API.fetch(link, { method: 'get' })
      data.value[key].country = response.data
      return response
    } catch (e) {
      API.handleError(e)
    }
  }

  const fetchAddress = async () => {
    if (data.value._links && data.value._links.address) {
      const response = await API.fetch(data.value._links.address.href, { method: 'get' })
      if (response.data) {
        data.value.address = response.data
        if (response.data._links && response.data._links.country) {
          try {
            await fetchCountry({ link: response.data._links.country.href, key: 'address' })
          } catch (e) {
            console.error(e)
          }
        }
      }
      return response
    }
  }

  const fetchHeadquarter = async () => {
    if (data.value._links && data.value._links.headQuarter) {
      const response = await API.fetch(data.value._links.headQuarter.href, { method: 'get' })
      if (response.data) {
        data.value.headquarter = response.data
        if (response.data._links && response.data._links.country) {
          try {
            await fetchCountry({ link: response.data._links.country.href, key: 'headquarter' })
          } catch (e) {
            console.error(e)
          }
        }
      }
      return response
    }
  }

  const fetchLogo = async () => {
    if (typeof data.value === 'object') {
      files.value.logo = null
      LogoFile.reset()
      if (data.value._links && data.value._links.logo) {
        const response = await LogoFile.fetch(data.value._links.logo.href)
        files.value.logo = response
        return response
      }
    }
  }

  const fetchCover = async () => {
    if (typeof data.value === 'object') {
      files.value.cover = null
      LogoFile.reset()
      if (data.value._links && data.value._links.cover) {
        const response = await CoverFile.fetch(data.value._links.cover.href)
        files.value.cover = response
        return response
      }
    }
  }

  const prepareUpdateData = (obj) => {
    const data = {
      companyName: obj.companyName,
      slogan: obj.slogan,
      description: obj.description,
      mail: obj.mail,
      businessName: obj.businessName,
      corporateName: obj.corporateName,
      activity: obj.activity,
      legalActivity: obj.legalActivity,
      codeNaf: obj.codeNaf,
      legalForm: obj.legalForm,
      commercialRegister: obj.commercialRegister,
      siren: obj.siren,
      siret: obj.siret,
      tvaNumber: obj.tvaNumber,
      phoneType: obj.phones[0] ? parseInt(obj.phones[0].type) : null,
      phoneAreaCode: obj.phones[0] ? obj.phones[0].areaCode : null,
      phoneNumber: obj.phones[0] ? obj.phones[0].number : null,
      altPhoneType: obj.phones[1] ? parseInt(obj.phones[1].type) : null,
      altPhoneAreaCode: obj.phones[1] ? obj.phones[1].areaCode : null,
      altPhoneNumber: obj.phones[1] ? obj.phones[1].number : null,
      capital: obj.capital ? obj.capital.toString() : null,
      network: obj.network
    }

    if (obj.address) {
      data.address = {
        id: obj.address.id,
        main: obj.address.main,
        additional: obj.address.additional,
        zipCode: obj.address.zipCode,
        city: obj.address.city,
        country: { id: obj.address.country.id }
      }
    }

    if (!obj.sameAddress && obj.headquarter) {
      data.headquarter = {
        id: obj.headquarter.id,
        main: obj.headquarter.main,
        additional: obj.headquarter.additional,
        zipCode: obj.headquarter.zipCode,
        city: obj.headquarter.city,
        country: { id: obj.headquarter.country.id }
      }
    }

    return data
  }

  const update = async (obj) => {
    if (!obj) { return }
    const data = prepareUpdateData(obj)
    try {
      const response = await API.fetch('/parameter', {
        method: 'put',
        data
      })
      setData(data)
      return response
    } catch (e) {
      API.handleError(e)
    }
  }

  return {
    ...CommonObjDatas,
    data,
    files,
    fetch,
    fetchAddress,
    fetchHeadquarter,
    fetchCountry,
    fetchLogo,
    fetchCover,
    update,
    LogoFile,
    CoverFile
  }
})
