import { defineStore } from 'pinia'
import { useAPI, useListWithCheck } from '@/composables'

export const useProjectsStore = defineStore('useProjectsStore', () => {
  const listWithCheck = useListWithCheck()
  const getProjectById = listWithCheck.getItemById

  const API = useAPI()
  const fetch = async () => {
    const response = await API.fetch('/projects', { method: 'get' })
    if (response) {
      listWithCheck.setList(response.data)
    }
    return response
  }

  const removeProject = (id) => {
    listWithCheck.removeItem(id)
  }

  return {
    ...listWithCheck,
    getProjectById,
    fetch,
    removeProject
  }
})
