import { computed, isRef, ref } from 'vue'
import moment from 'moment'
import { LOCALE } from '@/datas/globals'
import { useAPI } from '@/composables'

moment.locale(LOCALE)

export const useListWithCheck = () => {
  const list = ref([])

  const checkedItems = computed(() => {
    return allItems.value.reduce((acc, item) => {
      if (item.checked) {
        acc.push(item)
      }
      return acc
    }, [])
  })

  const checkedNb = computed(() => {
    return checkedItems.value.length
  })

  const getListNumber = computed(() => allItems.value.length)

  const allItems = computed(() => {
    return list.value.reduce((acc, item) => {
      if (item.items) {
        item.items.forEach(element => {
          acc.push(element)
        })
      } else {
        acc.push(item)
      }
      return acc
    }, [])
  })

  const getLastUpdated = computed(() => {
    const tmp = allItems.value.map((item) => item)
    const sortedArray = tmp.sort((a, b) => {
      const timeA = a.updatedAt ? moment(a.updatedAt).valueOf() : 0
      const timeB = b.updatedAt ? moment(b.updatedAt).valueOf() : 0
      if (timeA > timeB) {
        return -1
      }
      if (timeA < timeB) {
        return 1
      }
      return 0
    })
    return sortedArray.length ? sortedArray[0] : null
  })

  const getItemById = (id) => {
    return allItems.value.filter((item) => item.id === id)[0]
  }

  const removeItem = (id) => {
    let index = list.value.findIndex((item) => item.id === id)
    if (index > -1) {
      list.value.splice(index, 1)
    } else {
      list.value.forEach((item) => {
        if (item.items) {
          index = item.items.findIndex((el) => el.id === id)
          if (index > -1) {
            item.items.splice(index, 1)
          }
        }
      })
    }
  }

  const _mapChecked = (item) => {
    item.checked = false

    if (item.items) {
      item.items = item.items.map((item) => {
        item.checked = false
        return item
      })
    }
    return item
  }

  const resetChecked = () => {
    if (list.value.length) {
      list.value = list.value.map(_mapChecked)
    }
  }

  const setList = (arrayList) => {
    if (arrayList && !isRef(arrayList)) {
      list.value = arrayList.map(_mapChecked)
    }
  }

  const setItemChecked = (id) => {
    const item = getItemById(id)

    if (item) {
      item.checked = !item.checked
    }
  }

  const setLogo = (url, id) => {
    list.value.forEach((item) => {
      if (item.id === id) {
        item.logo = url
      }
    })
  }

  const setName = (name, id) => {
    const item = getItemById(id)
    if (item) {
      item.name = name
    }
  }

  const searchByName = (name) => {
    const regex = new RegExp('^' + name.toLowerCase(), 'g')
    return list.value.reduce((acc, item) => {
      if (item.name.toLowerCase().match(regex)) {
        acc.push(item)
      }
      return acc
    }, [])
  }

  const fetchUserLastUpdate = async () => {
    if (getLastUpdated.value && getLastUpdated.value._links && getLastUpdated.value._links.editor) {
      const API = useAPI()
      const response = await API.fetch(getLastUpdated.value._links.editor.href, { method: 'get' })
      return response
    }
  }

  return {
    list,
    allItems,
    getLastUpdated,
    checkedItems,
    checkedNb,
    getListNumber,
    getItemById,
    removeItem,
    resetChecked,
    setList,
    setItemChecked,
    setLogo,
    setName,
    searchByName,
    fetchUserLastUpdate
  }
}

export default useListWithCheck
