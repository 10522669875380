<script setup>
import { defineProps, defineEmits, ref, computed, nextTick } from 'vue'
import { FormFieldsProps } from '@/props'
import { useFormField } from '@/composables/useFormField'
import NbsIcon from '@/components/NbsIcon.vue'

const emit = defineEmits(['onToggle', 'onUpdate'])

const props = defineProps({
  ...FormFieldsProps,
  lockedText: String,
  icon: String,
  displayToggle: {
    type: Boolean,
    default: true
  },
  colorOff: {
    type: String,
    default: 'light'
  },
  colorOn: {
    type: String,
    default: 'green'
  },
  vanillaMode: {
    type: Boolean,
    default: false
  }
})

const model = ref(props.default)
const FormField = useFormField({ props, defaultValue: model.value, emit })

const onChange = async () => {
  model.value = !model.value
  emit('onToggle', model.value)

  await nextTick()
  FormField.updateState(model.value)
}

const isLocked = computed(() => {
  return props.isDisabled && model.value
})

const classList = computed(() => {
  return {
    'nbs-switch--disabled': props.isDisabled,
    'nbs-switch--locked': isLocked.value,
    'nbs-switch--notoggle': !props.displayToggle,
    'nbs-switch--updated': FormField.isUpdated.value,
    'nbs-switch--vanilla': props.vanillaMode
  }
})
</script>

<template>
  <div class="nbs-switch" :class="[classList, 'nbs-switch--' + props.colorOff + 'Off', 'nbs-switch--' + props.colorOn + 'On']">
    <input type="checkbox" :id="props.id" @change="onChange" :disabled="props.isDisabled || !props.displayToggle">
    <label :for="props.id" class="nbs-switch-btn">
      <span>
        <nbs-icon v-if="props.icon" :icon="props.icon" class="nbs-switch-icon"></nbs-icon>
        {{ props.label }}
      </span>

      <span v-if="isLocked" class="nbs-switch-locked">
        {{ props.lockedText }} <nbs-icon icon="locked" class="nbs-switch-lockedIcon"></nbs-icon>
      </span>
      <span v-else-if="props.displayToggle" class="nbs-switch-btnIcon" :class="{ 'nbs-switch-btnIcon--model': model }">
        <span class="nbs-switch-btnIconCircle"></span>
      </span>
    </label>
    <div class="nbs-switch-content" v-if="model">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/styles/_mixins.scss';
@import '@/assets/styles/_variables.scss';

.nbs-switch {
  position: relative;
  margin: rem(16px) 0;
  border-radius: rem(12px);
  padding: $gutters;
  overflow: hidden;
  background-color: $black-04;
  border: rem(2px) solid transparent;
  cursor: pointer;
  transition: $transition-border;

  &--lightOff {
    color: $black-48;

    .nbs-switch-btnIcon {
      &::before,
      &::after {
        background-color: $black-48;
      }
    }
  }

  &--darkOff {
    color: $black-88;

    .nbs-switch-btnIcon {
      &::before,
      &::after {
        background-color: $black-88;
      }
    }
  }

  &--greenOn {
    .nbs-switch-btnIcon--model {
      &::before,
      &::after {
        background-color: $green;
      }
    }
  }

  &--vanilla {
    margin: 0;
    padding: 0;
    background: none;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--disabled {
    cursor: not-allowed;
    color: $black-24;

    .nbs-switch-btn {
      cursor: not-allowed;
    }

    .nbs-switch-btnIcon {
      &::before,
      &::after {
        background-color: $black-24;
      }
    }
  }

  &--locked {
    color: $black-88;
  }

  &--notoggle {
    &, .nbs-switch-btn {
      cursor: default;
    }
  }

  &--updated {
    border-color: $purple;
  }

  & > input[type="checkbox"] {
    appearance: none;
    position: absolute;
    top: rem(-5px);
    height: 0;
    width: 0;
  }

  &-icon {
    display: inline-block;
    margin-right: rem(16px);
    color: $black-24;
  }

  &-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: rem(16px);
    font-weight: 500;
    cursor: pointer;

    &Icon {
      position: relative;
      z-index: 0;
      display: flex;
      width: rem(32px);
      height: rem(16px);

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: rem(16px);
        height: rem(16px);
      }

      &::before {
        left: 0;
        border-radius: 50% 0 0 50%;
      }

      &::after {
        right: 0;
        border-radius: 0 50% 50% 0;
      }

      &Circle {
        position: relative;
        z-index: 1;
        margin: rem(1px);
        width: rem(14px);
        height: rem(14px);
        background-color: $white;
        border-radius: 50%;
        transition: transform 0.2s linear;
      }

      &--model {
        .nbs-switch-btnIconCircle {
          transform: translateX(rem(16px));
        }
      }
    }
  }

  &-locked {
    display: flex;
    align-items: center;
    color: $black-48;

    &Icon {
      margin-left: rem(8px);
    }
  }
}
</style>
