<script setup>
import { defineProps, computed } from 'vue'
const props = defineProps(['icon', 'iconClass', 'color', 'folder', 'size'])

const colorClass = computed(() => {
  return props.color ? 'icon--' + props.color : ''
})
const sizeClass = computed(() => {
  return props.size ? 'icon--' + props.size : ''
})
const folderPath = computed(() => {
  return props.folder ? props.folder + '/' : ''
})
const iconAnchor = computed(() => {
  return (props.folder ? '' : 'src--assets--icons--') + props.icon
})
</script>

<template>
    <svg class="icon" :class="[ props.iconClass, colorClass, sizeClass ]">
        <use v-bind="{'xlink:href':'/sprite/' + folderPath + 'defs/svg/sprite.' + (props.folder ? 'defs' : 'css') + '.svg#' + iconAnchor }"></use>
    </svg>
</template>

<style lang="scss">
@import '@/assets/styles/_mixins.scss';
@import '@/assets/styles/_variables.scss';

.icon {
  width: rem(16px);
  height: rem(16px);
  vertical-align: middle;

  &--24 {
    width: rem(24px);
    height: rem(24px);
  }

  &--32 {
    width: rem(32px);
    height: rem(32px);
  }

  &--48 {
    width: rem(48px);
    height: rem(48px);
  }

  &--64 {
    width: rem(64px);
    height: rem(64px);
  }

  &--light {
    color: $black-32;
  }

  &--red {
    color: $red
  }

  &--orange {
    color: $orange
  }

  &--green {
    color: $green
  }

  &--purple {
    color: $purple;
  }

  &--blue {
    color: $blue;
  }

  &--pink {
    color: $pink;
  }
}
</style>
