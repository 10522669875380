import { computed, ref } from 'vue'
import { useLeaveFormModalStore } from '@/store/leaveFormModal'

export const useEditForm = () => {
  const leaveFormModalStore = useLeaveFormModalStore()

  const isSubmitted = ref(false)
  const isLocked = ref(false)

  const isEditProgressing = computed(() => {
    return leaveFormModalStore.isEditing
  })

  const numberFieldsText = computed(() => {
    return leaveFormModalStore.editCount > 0 ? `${leaveFormModalStore.editCount} champ${leaveFormModalStore.editCount > 1 ? 's' : ''}` : ''
  })

  const titleText = computed(() => {
    if (isLocked.value) {
      return 'Le contenu de cette rubrique est verrouillé'
    } else {
      return leaveFormModalStore.editCount > 0 ? 'Vous avez modifié' : 'Vous n\'avez rien modifié'
    }
  })

  const onEditField = ({ newValue, oldValue, initialValue }) => {
    if (oldValue === newValue && oldValue === initialValue && newValue === oldValue) {
      return
    }
    isSubmitted.value = false
    if (newValue !== initialValue && oldValue === initialValue) {
      leaveFormModalStore.editCount++
    } else if (newValue === initialValue && leaveFormModalStore.editCount > 1) {
      leaveFormModalStore.editCount--
    }
  }

  const init = (locked) => {
    isLocked.value = locked
    leaveFormModalStore.isEditForm = isEditProgressing
  }

  return {
    editCount: computed(() => leaveFormModalStore.editCount),
    isSubmitted,
    isLocked,
    isEditProgressing,
    numberFieldsText,
    titleText,
    onEditField,
    handleLeaveFormModal: leaveFormModalStore.handleOpenModal,
    setChangeRouteUrl: leaveFormModalStore.setChangeRouteUrl,
    reset: leaveFormModalStore.reset,
    init
  }
}

export default useEditForm
