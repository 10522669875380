import { defineStore } from 'pinia'
import { computed } from 'vue'
import moment from 'moment'
import { LOCALE } from '@/datas/globals'
import { useAPI, useListWithCheck } from '@/composables'
import { StatusData } from '@/datas/customers/status'
import { COMPANY_TYPE, PUBLIC_SERVICE_TYPE, ASSOCIATION_TYPE, PRIVATE_PEOPLE_TYPE } from '@/datas/customers/types'

moment.locale(LOCALE)

export const useCustomersStore = defineStore('useCustomersStore', () => {
  const listWithCheck = useListWithCheck()

  const recentlyAddedCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => moment(item.createdAt).format('MMM Do YY') === moment().format('MMM Do YY'))
  })

  const recentlyEditedCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => moment(item.updatedAt).format('MMM Do YY') === moment().format('MMM Do YY'))
  })

  const archivedCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.status === StatusData.STATUS_DISABLED)
  })

  const certifiedCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.status === StatusData.STATUS_CERTIFIED)
  })

  const suspendedCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.status === StatusData.STATUS_DISABLED)
  })

  const companyCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.type === COMPANY_TYPE)
  })

  const publicCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.type === PUBLIC_SERVICE_TYPE)
  })

  const associationCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.type === ASSOCIATION_TYPE)
  })

  const privatePeopleCustomers = computed(() => {
    return listWithCheck.allItems.value.filter((item) => item.type === PRIVATE_PEOPLE_TYPE)
  })

  const getCertifiedNumber = computed(() => {
    return certifiedCustomers.value.length
  })

  const getInProgressNumber = computed(() => {
    return listWithCheck.allItems.value.length
  })

  const getSuspendedNumber = computed(() => {
    return suspendedCustomers.value.length
  })

  const getLastUpdated = computed(() => {
    const tmp = listWithCheck.allItems.value.map((item) => item)
    const sortedArray = tmp.sort((a, b) => {
      const timeA = a.updatedAt ? moment(a.updatedAt).valueOf() : 0
      const timeB = b.updatedAt ? moment(b.updatedAt).valueOf() : 0
      if (timeA > timeB) {
        return -1
      }
      if (timeA < timeB) {
        return 1
      }
      return 0
    })
    return sortedArray.length ? sortedArray[0] : null
  })

  const fetch = async () => {
    const API = useAPI()
    const response = await API.fetch('/customers', { method: 'get' })
    if (response) {
      listWithCheck.setList(response.data)
    }
    return response
  }

  return {
    ...listWithCheck,
    recentlyAddedCustomers,
    recentlyEditedCustomers,
    archivedCustomers,
    certifiedCustomers,
    companyCustomers,
    publicCustomers,
    associationCustomers,
    privatePeopleCustomers,
    getCertifiedNumber,
    getInProgressNumber,
    getSuspendedNumber,
    getLastUpdated,
    fetch
  }
})
