<script setup>
import { watch, ref, onBeforeMount, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAuthentificationStore } from '@/store/authentification'
import { useProjectsStore } from '@/store/projects'
import { useCustomersStore } from '@/store/customers'
import { useContactsStore } from '@/store/contacts'
import { useSettingsStore } from '@/store/settings'
import { useModalStore } from '@/store/modal'
import * as PublicRoutes from './datas/public/routes'
import { ROUTE_BASE as ROUTE_PLANNING } from './datas/planning/routes'
import { ROUTE_BASE as ROUTE_CUSTOMERS } from './datas/customers/routes'
import { ROUTE_BASE as ROUTE_PROJECTS } from './datas/projects/routes'
import { ROUTE_BASE as ROUTE_COMMUNITY } from './datas/community/routes'
import { ROUTE_BASE as ROUTE_QUIZ } from './datas/quiz/routes'
import { ROUTE_BASE as ROUTE_COMMUNICATION } from './datas/communication/routes'
import { ROUTE_BASE as ROUTE_ACCOUNTING } from './datas/accounting/routes'
import { ROUTE_BASE as ROUTE_STATS } from './datas/stats/routes'
import { ROUTE_BASE as ROUTE_SETTINGS, ROUTE_MY_PROFILE } from './datas/settings/routes'
import { ROUTE_BASE as ROUTE_SUPPORT } from './datas/support/routes'
import { ROUTE_BASE as ROUTE_HELP } from './datas/help/routes'
import NbsIcon from '@/components/NbsIcon.vue'
import NbsLogo from '@/components/NbsLogo.vue'
import NbsCard from '@/components/cards/NbsCard.vue'
import NbsMenu from '@/components/NbsMenu.vue'
import NbsUserIcon from '@/components/svg/NbsUserIcon.vue'
import NbsModal from '@/components/modals/NbsModal.vue'
import NbsConfirm from '@/components/modals/NbsConfirm.vue'

const authentificationStore = useAuthentificationStore()
const projectsStore = useProjectsStore()
const customersStore = useCustomersStore()
const contactsStore = useContactsStore()
const settingsStore = useSettingsStore()
const modalStore = useModalStore()
const router = useRouter()
const route = useRoute()
const isLoading = ref(false)

router.beforeEach(async (to) => {
  isInactive.value = false
  isLoading.value = true
  activateActivityTracker()
  if (authentificationStore.isLogged && to.path.includes(PublicRoutes.ROUTE_SECURE_ACCOUNT)) {
    authentificationStore.disconnect()
    return to
  } else if (!authentificationStore.isLogged && !to.path.includes(PublicRoutes.ROUTE_LOGIN_BASE)) {
    return { path: PublicRoutes.ROUTE_LOGIN_BASE, query: { from: to.fullPath } }
  } else if (authentificationStore.isLogged && to.path.includes(PublicRoutes.ROUTE_LOGIN_BASE)) {
    return { path: '/' }
  } else {
    if (authentificationStore.isLogged) {
      customersStore.resetChecked()
      projectsStore.resetChecked()
      contactsStore.resetChecked()
      if (customersStore.getListNumber === 0) {
        try {
          await customersStore.fetch()
        } catch (e) {
          console.error(e)
        }
      }
      if (projectsStore.getListNumber === 0) {
        try {
          await projectsStore.fetch()
        } catch (e) {
          console.error(e)
        }
      }
      if (!authentificationStore.loggedUser.login) {
        try {
          await authentificationStore.fetch()
        } catch (e) {
          console.error(e)
        }
      }
      if (contactsStore.getListNumber === 0) {
        try {
          await contactsStore.fetch()
        } catch (e) {
          console.error(e)
        }
      }
      if (!settingsStore.data.reference) {
        try {
          await settingsStore.fetch()
        } catch (e) {
          console.error(e)
        }
      }
      if (settingsStore.data._links && settingsStore.data._links.logo && !settingsStore.files.logo) {
        try {
          await settingsStore.fetchLogo()
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
})

router.afterEach(() => {
  isLoading.value = false
})

const NAV_ADMIN = ref([
  {
    icon: {
      name: 'dashboard'
    },
    label: 'Tableau de bord',
    url: '/'
  },
  {
    icon: {
      name: 'calendar-checked'
    },
    label: 'Planning',
    url: ROUTE_PLANNING
  },
  {
    icon: {
      name: 'customers'
    },
    label: 'Clients',
    url: ROUTE_CUSTOMERS,
    itemsLength: customersStore.getListNumber,
    notifAlert: true
  },
  {
    icon: {
      name: 'folder'
    },
    label: 'Projets',
    url: ROUTE_PROJECTS,
    itemsLength: projectsStore.getListNumber
  },
  {
    icon: {
      name: 'community'
    },
    label: 'Utilisateurs',
    url: ROUTE_COMMUNITY,
    itemsLength: contactsStore.getListNumber
  },
  {
    icon: {
      name: 'send-2'
    },
    label: 'Newsletter',
    url: ROUTE_COMMUNICATION,
    isGroup: true
  },
  {
    icon: {
      name: 'catalog'
    },
    label: 'Catalogue produits',
    url: '/products',
    isGroup: true
  },
  {
    icon: {
      name: 'ampoule-question'
    },
    label: 'Questionnaires',
    url: ROUTE_QUIZ
  },
  {
    icon: {
      name: 'accounting'
    },
    label: 'Comptabilité',
    url: ROUTE_ACCOUNTING,
    isGroup: true
  },
  {
    icon: {
      name: 'stats'
    },
    label: 'Statistique',
    url: ROUTE_STATS
  },
  {
    icon: {
      name: 'settings'
    },
    label: 'Paramètres',
    url: ROUTE_SETTINGS,
    isGroup: true
  },
  {
    icon: {
      name: 'support'
    },
    label: 'Support',
    url: ROUTE_SUPPORT
  },
  {
    icon: {
      name: 'help'
    },
    label: 'Aides et informations',
    url: ROUTE_HELP
  }
])

const NAV_CONTACT = ref([
  {
    icon: {
      name: 'dashboard'
    },
    label: 'Tableau de bord',
    url: '/'
  }
])

const INACTIVE_USER_TIME_THRESHOLD = 60000
const USER_ACTIVITY_THROTTLER_TIME = 1000
const isInactive = ref(false)
let userActivityTimeout = null
let userActivityThrottlerTimeout = null

const submitInactivityModal = () => {
  authentificationStore.disconnect()
  modalStore.open({
    component: NbsConfirm,
    closeButton: false,
    props: {
      icon: 'rounded-user-locked',
      title: 'Vous avez été déconnecté',
      desc1: 'Votre session Beautiful System a été fermée par mesure de sécurité.',
      desc2: 'Conformément à notre politique de protection des données, vous avez été déconnecté de votre espace personnel suite à une longue période d’inactivité.',
      deleteBtn: { title: 'Je m’identifie de nouveau', class: 'nbs-btn--primary' },
      displayCancelBtn: false,
      onSubmit: modalStore.close
    }
  })
}

const inactiveUserAction = () => {
  isInactive.value = true
  modalStore.open({
    component: NbsConfirm,
    closeButton: false,
    props: {
      icon: 'warning',
      desc1: 'Suite à une longue période d’inactivité, vous allez être déconnecté dans quelques instants.',
      desc2: 'Souhaitez-vous prolonger votre session Beautiful System ?',
      deleteBtn: { title: 'Déconnexion', class: 'nbs-btn--warning' },
      cancelBtn: { title: 'Continuer', class: 'nbs-btn--primary' },
      onCancel: () => {
        isInactive.value = false
      },
      onSubmit: submitInactivityModal
    }
  })
  // disconnect the user if the inactivity is more than 1 minutes after the modal display
  userActivityTimeout = setTimeout(() => {
    submitInactivityModal()
    resetUserActivityTimeout()
  }, INACTIVE_USER_TIME_THRESHOLD)
}

const resetUserActivityTimeout = () => {
  if (authentificationStore.isLogged) {
    clearTimeout(userActivityTimeout)
    userActivityTimeout = setTimeout(() => {
      inactiveUserAction()
    }, INACTIVE_USER_TIME_THRESHOLD)
  }
}

const userActivityThrottler = () => {
  if (!userActivityThrottlerTimeout) {
    userActivityThrottlerTimeout = setTimeout(() => {
      resetUserActivityTimeout()

      clearTimeout(userActivityThrottlerTimeout)
      userActivityThrottlerTimeout = null
    }, USER_ACTIVITY_THROTTLER_TIME)
  }
}

const activateActivityTracker = () => {
  if (authentificationStore.isLogged) {
    resetUserActivityTimeout()
    window.addEventListener('mousemove', userActivityThrottler)
    window.addEventListener('scroll', userActivityThrottler)
    window.addEventListener('keydown', userActivityThrottler)
    window.addEventListener('resize', userActivityThrottler)
    window.addEventListener('beforeunload', deactivateActivityTracker)
  }
}

const deactivateActivityTracker = () => {
  window.removeEventListener('mousemove', userActivityThrottler)
  window.removeEventListener('scroll', userActivityThrottler)
  window.removeEventListener('keydown', userActivityThrottler)
  window.removeEventListener('resize', userActivityThrottler)
  window.removeEventListener('beforeunload', deactivateActivityTracker)

  clearTimeout(userActivityTimeout)
  clearTimeout(userActivityThrottlerTimeout)
}

onBeforeMount(() => {
  if (authentificationStore.isLogged) {
    activateActivityTracker()
  }
})

onBeforeUnmount(() => {
  deactivateActivityTracker()
})

watch(() => authentificationStore.token, (newValue) => {
  if (newValue === '') {
    resetUserActivityTimeout()
    deactivateActivityTracker()
    router.push({ path: PublicRoutes.ROUTE_LOGIN_BASE, query: { from: route.fullPath } })
  } else {
    if (authentificationStore.isLogged) {
      activateActivityTracker()
    }
  }
})

watch(() => customersStore.getListNumber, (newValue) => {
  NAV_ADMIN.value[2].itemsLength = newValue
})

watch(() => projectsStore.getListNumber, (newValue) => {
  NAV_ADMIN.value[3].itemsLength = newValue
})

watch(() => contactsStore.getListNumber, (newValue) => {
  NAV_ADMIN.value[6].itemsLength = newValue
})
</script>

<template>
  <div class="nbs-main" :class="{ 'nbs-main--login' : !authentificationStore.isLogged, 'nbs-main--blured': isInactive && modalStore.isOpened }">
    <header class="nbs-header" v-if="authentificationStore.isLogged">
      <nbs-icon icon="bs" class="nbs-header-logo"/>
      <router-link type="button" class="nbs-header-btn" :to="ROUTE_SETTINGS + '/' + ROUTE_MY_PROFILE">
        <img
          v-if="authentificationStore.files.avatar"
          class="nbs-header-avatar" alt=""
          :src="authentificationStore.files.avatar.fullpath">
        <nbs-user-icon class="nbs-header-avatar" v-else></nbs-user-icon>
      </router-link>
    </header>
    <div class="nbs-nav" v-if="authentificationStore.isLogged">
      <nbs-card class="nbs-nav-card" :route="ROUTE_SETTINGS">
        <div class="nbs-card-header nbs-nav-cardHeader">
          <nbs-logo :url="settingsStore.files.logo ? settingsStore.files.logo.fullpath : ''"></nbs-logo>
        </div>
        <div class="nbs-card-content nbs-nav-cardContent">
          <div role="heading" aria-level="1" class="nbs-card-title nbs-nav-cardTitle">{{ settingsStore.data.companyName }}</div>
          {{ settingsStore.data.reference }}
        </div>
      </nbs-card>
      <nbs-menu :items="authentificationStore.isAdmin ? NAV_ADMIN : NAV_CONTACT"></nbs-menu>
    </div>
    <div :class="{ 'nbs-main-login' : !authentificationStore.isLogged }">
      <router-view :key="$route.fullPath" v-if="!isLoading" />
    </div>
  </div>
  <nbs-modal></nbs-modal>
</template>

<style lang="scss">
@use 'sass-mq' as *;
@import './assets/styles/_mixins.scss';
@import './assets/styles/_variables.scss';

.nbs-main {
    display: grid;
    grid-gap: 0;
    grid-template-columns: rem(64px) rem(132px) auto;
    grid-auto-rows: minmax(rem(100px), auto);
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $black-88;

    @include mq($from: wide) {
      grid-template-columns: rem(64px) rem(300px) auto;
    }

    &--login {
      display: block;
    }

    &--blured {
      filter: blur(16px);
    }

    &-login {
      @include mq($from: desktop) {
        display: grid;
        grid-gap: 0;
        grid-template-columns: 64% auto;
        height: 100%;
      }
    }
}

.nbs-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: rem(20px) 0;
  background: $black-88;

  &-logo {
    width: rem(24px);
    height: rem(24px);
    color: $white;
  }

  &-avatar {
    max-width: 100%;
    border-radius: 50%;
  }

  &-btn {
    border: 0;
    border-radius: 50%;
    padding: rem(2px);
    width: rem(28px);
    height: rem(28px);
    background: $white;
  }
}

.nbs-nav {
  border-right: rem(1px) solid $black-08;
  padding: rem(20px) $gutters;
  overflow-y: auto;
  overflow-x: hidden;

  &-card {
    display: flex;
    align-items: center;
    color: $black-64;

    &Header {
      @include mq($from: wide) {
        width: rem(32px);
        height: rem(32px);
        margin-right: $gutters;
        border-radius: 50%;
        overflow: hidden;
        background-color: $black-04;
      }
    }

    &Content {
      display: none;

      @include mq($from: wide) {
        display: block;
      }
    }

    &Title {
      text-transform: uppercase;
      color: $black-88;
    }
  }

  &-menu {
    padding: rem(20px) 0;
  }
}
</style>
