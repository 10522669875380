export const ROUTE_BASE = '/quiz'
export const ROUTE_ADD = 'add'
export const ROUTE_EDIT = 'edit'
export const ROUTE_QUIZ_CONTENT = 'content'
export const ROUTE_QUIZ_USE = 'use'

export default {
  ROUTE_BASE,
  ROUTE_ADD,
  ROUTE_EDIT,
  ROUTE_QUIZ_CONTENT,
  ROUTE_QUIZ_USE
}
