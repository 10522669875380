<script setup>
import { defineProps, computed, ref } from 'vue'
import { useModalStore } from '@/store/modal'
import NbsIcon from '@/components/NbsIcon.vue'
import NbsSwitch from '@/components/forms/NbsSwitch.vue'
import NbsCheckbox from '../forms/NbsCheckbox.vue'

const modalStore = useModalStore()

const props = defineProps({
  icon: {
    type: String,
    default: 'bin'
  },
  title: {
    type: String,
    default: 'Attention !'
  },
  desc1: String,
  desc2: String,
  deleteBtn: {
    type: Object,
    default: () => {
      return {
        title: 'Supprimer',
        class: ['nbs-btn--warning']
      }
    }
  },
  cancelBtn: {
    type: Object,
    default: () => {
      return {
        title: 'Annuler',
        class: ['nbs-btn--secondary']
      }
    }
  },
  onSubmit: {
    type: Function,
    default: () => false
  },
  onCancel: {
    type: Function,
    default: () => false
  },
  displayCheckbox: {
    type: Boolean,
    default: false
  },
  displayCancelBtn: {
    type: Boolean,
    default: true
  },
  externalNeeded: {
    type: Boolean,
    default: false
  },
  externalText: {
    type: String,
    default: ''
  }
})

const externalModel = ref(false)
const continueAction = ref(false)
const displayedButton = computed(() => {
  return (!props.externalNeeded && !props.displayCheckbox) ||
    externalModel.value ||
    continueAction.value ||
    (props.displayCheckbox && continueAction.value)
})

const cancel = () => {
  modalStore.close()
  if (typeof props.onCancel === 'function') {
    props.onCancel()
  }
}
</script>
<template>
  <div class="nbs-confirm">
    <nbs-icon :icon="props.icon" class="nbs-confirm-icon"/>
    <h1 class="h2">{{ props.title }}</h1>
    <p class="txt-medium" v-html="props.desc1"></p>
    <p class="txt-light" v-html="props.desc2"></p>
    <div v-if="props.externalNeeded || props.displayCheckbox" class="nbs-confirm-externalNeeded">
      <nbs-switch
        v-if="props.externalNeeded"
        label="Demander la confirmation"
        id="confirm-needed"
        :default="externalModel"
        @on-toggle="(v) => externalModel = v">
        <p>{{ props.externalText }}</p>
      </nbs-switch>
      <nbs-checkbox
        v-if="!externalModel || props.displayCheckbox"
        label="J’ai compris et je souhaite continuer mon action."
        :default="continueAction"
        @on-change="(params) => continueAction = params.checked"
        ></nbs-checkbox>
    </div>
    <button v-if="props.displayCancelBtn" type="button" class="nbs-btn nbs-confirm-cancel" :class="props.cancelBtn.class" @click="cancel">{{ props.cancelBtn.title }}</button>
    <button v-if="displayedButton" @click="props.onSubmit" type="button" class="nbs-btn nbs-confirm-submit" :class="props.deleteBtn.class">{{ props.deleteBtn.title }}</button>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/styles/_mixins.scss';
@import '@/assets/styles/_variables.scss';

.nbs-confirm {
  padding: rem(32px);
  text-align: center;
  &-icon {
    width: rem(64px);
    height: rem(64px);
  }

  &-externalNeeded {
    text-align: left;
  }

  &-submit {
    margin: rem(24px) 0 0;
  }

  &-cancel {
    margin: rem(24px) 0 0;

    & + .nbs-confirm-submit {
      margin-top: rem(16px);
    }
  }
}

.nbs-btn {
  width: 100%;
}
</style>
