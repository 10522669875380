export const COMPANY_TYPE = 0
export const PUBLIC_SERVICE_TYPE = 1
export const ASSOCIATION_TYPE = 2
export const PRIVATE_PEOPLE_TYPE = 3

export const TypesValues = {
  COMPANY_TYPE,
  PUBLIC_SERVICE_TYPE,
  ASSOCIATION_TYPE,
  PRIVATE_PEOPLE_TYPE
}

export const TypesData = [
  { label: 'Société', value: COMPANY_TYPE },
  { label: 'Service public', value: PUBLIC_SERVICE_TYPE },
  { label: 'Association', value: ASSOCIATION_TYPE },
  { label: 'Particulier', value: PRIVATE_PEOPLE_TYPE }
]

export const FilesTypesData = {
  KBIS: 0,
  CGV: 1,
  RIB: 2,
  SEPA: 3,
  LOGO: 4,
  ID: 5
}

export const NetworksTypesDropdown = [
  { title: 'Site internet de l\'entreprise', value: 0 },
  { title: 'Boutique de l\'entreprise', value: 1 },
  { title: 'Facebook de l\'entreprise', value: 2 },
  { title: 'Instagram de l\'entreprise', value: 3 },
  { title: 'Tiktok de l\'entreprise', value: 4 },
  { title: 'Snapchat de l\'entreprise', value: 5 },
  { title: 'X/twitter de l\'entreprise', value: 6 },
  { title: 'Threads de l\'entreprise', value: 7 },
  { title: 'Youtube de l\'entreprise', value: 8 },
  { title: 'Dailymotion de l\'entreprise', value: 9 },
  { title: 'Twitch de l\'entreprise', value: 10 },
  { title: 'Linked-In de l\'entreprise', value: 11 },
  { title: 'Tripadvisor de l\'entreprise', value: 12 },
  { title: 'Pinterest de l\'entreprise', value: 13 }
]
